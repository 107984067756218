// CertificateFiles.js
import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig';
import config from '../config';
import './CertificateFiles.css';

const CertificateFiles = ({ ccsCode, certificateId, isAdmin }) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/certificates/${certificateId}/files`);
        setFiles(response.data.files);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };

    fetchFiles();
  }, [certificateId]);

  const deleteFile = async (fileName) => {
    try {
      await axios.delete(`${config.apiUrl}/certificates/${certificateId}/delete-file`, {
        data: { ccsCode, fileName },
      });
      setFiles(files.filter(file => file !== fileName));
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  return (
    <div className="certificate-files">
      <h4>Documents for Due Diligence:</h4>
      <ol>
        {files.map((file, index) => (
          <li className="certificate-file-li" key={index}>
            <a className="certificate-file-link" href={`${config.baseApi}/uploads/${ccsCode}/${certificateId}/${file}`} target="_blank" rel="noopener noreferrer">{file} (Download)</a>
            {isAdmin ? (
              <button className="delete-file-button" onClick={() => deleteFile(file)}>
                x
              </button>
            ) : ''}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default CertificateFiles;
