// Login.js
import React from 'react';
import LoginForm from './components/LoginForm';
import { Container, Row, Col } from 'react-bootstrap';

const Login = ({ onLogin }) => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Container className="d-flex flex-grow-1 justify-content-center align-items-center">
        <LoginForm onLogin={onLogin} />
      </Container>
      <footer className="bg-transparent py-3">
        <Container className="text-center">
          <p><small className="mb-0">© 2023 Foreign Investment Company S/A</small></p>
        </Container>
        <Container>
          <Row className="justify-content-center mt-2 text-center">
            <Col>
              <small><a href="https://ficoglobal.com/what-is-ccs">What is CCS?</a></small>
            </Col>
            <Col>
              <small><a href="https://ficoglobal.com">Go to ficoglobal.com</a></small>
            </Col>
            <Col>
              <small><a target="_blank" rel="noreferrer" href="https://ccs.ficoglobal.com/privacy-policy.html">Privacy Policy</a></small>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Login;
