import React, { useState, useEffect } from 'react';
import { Card, Button, CardGroup, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import config from './config';

const CertificateGuestView = ({ readonlyToken, onLogout }) => {
  const [endpointInfo, setEndpointInfo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const instance = axios.create();
      const requestConfig = {
        headers: { Authorization: `Bearer ${readonlyToken}` },
      };
      const userResponse = await instance.get(`${config.apiUrl}/guestview/data`, requestConfig);
      setEndpointInfo(userResponse.data);
    };
  
    fetchData();
  }, [readonlyToken]);
  

  return (
    <div className='p-3 container'>
      {endpointInfo && (
        <div>
          <CardGroup className="card-group-container text-center d-flex align-items-center justify-content-center">
          <Row>
            <Col md={12}>
              <Card className="ccs-card p-3">
                <Card.Body>
                <h1 class="h3">{endpointInfo.ID}</h1>
                <h2 className="h5">KYC & Certificate Information</h2>
                <Button size="lg" variant="warning"  className="my-2 mt-2" onClick={onLogout}>Exit</Button>
                <Row>
                {Object.entries(endpointInfo).map(([key, value], index, array) => {
                    if (index === array.length - 1) {
                      return null;  // don't render anything for the last item
                    }

                    return (
                      <Col key={key} sm={12}>
                        <Row>
                          <Col sm={6}>
                            <p><small>{key}</small></p>
                          </Col>
                          <Col sm={6}>
                            <p>{value}</p>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                  </Row>
                  <h2>Documents for Due Diligence & Compliance</h2>
                  {endpointInfo.files.map((file, index) => (
                    <li key={index} className="certificate-file-li">
                      <a className="certificate-file-link" href={`${config.baseApi}/uploads/${endpointInfo.ccs_code}/${endpointInfo.ID}/${file}`} target="_blank" rel="noopener noreferrer">{file} (Download)</a>
                    </li>
                  ))}
                </Card.Body>
              </Card>
              <Card className="ccs-card text-left mt-3 p-3">
                <Card.Body>
                  <h2 className="h5">Support Material</h2>
                    <p><small><a target="_blank" rel="noreferrer" className="support-material-link" href="https://ficoglobal.com/ifc/">Understanding the IFC Methodology</a></small><br/>
                    <small><a target="_blank" rel="noreferrer" className="support-material-link" href="https://www.icmagroup.org/sustainable-finance/membership-governance-and-working-groups/membership/#:~:text=Banco%20FICO">Carbon Bank FICO (Brazil) - ICMA Observer Member credentials</a></small><br/>
                    <small><a target="_blank" rel="noreferrer" className="support-material-link" href="https://wedocs.unep.org/handle/20.500.11822/9771">Inclusive Wealth Report 2014 - UN (conservation as a form of wealth)</a></small></p>
                  <p><small>FICO S/A (Headquarters): Registro Publico Nº 132308 - Foreign Investment Company S/A - Republica del Panama</small><br/>
                  <small>Carbon Bank FICO (Brazilian branch): CNPJ Nº 29.289.880/0001-40 - Banco FICO de Carbono Florestal LTDA - República Federativa do Brasil</small></p>
                </Card.Body>
              </Card>
          </Col>
          </Row>
          </CardGroup>
        </div>
      )}
    </div>
  );
};

export default CertificateGuestView;
