import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import CountrySelect from './CountrySelect';
import config from '../config';

const CertificateForm = ({ onSuccess, selectedCertificate, onEditFinished }) => {
  const formatDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const [files, setFiles] = useState(null);
  const handleFileChange = (event) => {
    setFiles([...event.target.files]);
  };
  const [message, setMessage] = useState('');
  const [formResetCounter, setFormResetCounter] = useState(0);
  const [formData, setFormData] = useState({
    person_name: '',
    person_register: '',
    person_country: '',
    ccs_code: '',
    parent_id: '',
    lease_type: '',
    expiration_date: '',
    currency: '',
    face_value: '',
    financial_monetizer: '',
    financial_applicant: '',
    maturity: '',
    avoided_emissions: '',
    blockchain_url: '',
    opportunity_cost_price: '',
    market: '',
  });
  const handleChange = (event) => {
    const { name, value, type } = event.target;
  
    // Check if the input type is 'date', and if so, convert the date string back to a Unix timestamp
    const newValue = type === 'date' ? Math.floor(new Date(value).getTime() / 1000) : value;
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };
  const resetForm = () => {
    setFormData({
      person_name: '',
      person_register: '',
      person_country: '',
      ccs_code: '',
      parent_id: '',
      lease_type: '',
      expiration_date: '',
      currency: '',
      face_value: '',
      financial_monetizer: '',
      financial_applicant: '',
      maturity: '',
      avoided_emissions: '',
      blockchain_url: '',
      opportunity_cost_price: '',
      market: '',
    });
    setFormResetCounter((prevCounter) => prevCounter + 1);
  };
  useEffect(() => {
    if (selectedCertificate) {
      setFormData(mapSelectedCertificateToFormData(selectedCertificate));
    } else {
      resetForm();
    }
  }, [selectedCertificate]);
  const mapSelectedCertificateToFormData = (certificate) => {
    return {
      person_name: certificate.person_name,
      person_register: certificate.person_register,
      person_country: certificate.person_country,
      ccs_code: certificate.ccs_code,
      parent_id: certificate.parent_id,
      lease_type: certificate.lease_type,
      currency: certificate.currency,
      face_value: certificate.face_value,
      financial_monetizer: certificate.financial_monetizer,
      financial_applicant: certificate.financial_applicant,
      avoided_emissions: certificate.avoided_emissions,
      opportunity_cost_price: certificate.opportunity_cost_price,
      market: certificate.market,
      blockchain_url: certificate.blockchain_url,
      expiration_date: selectedCertificate.expiration_date,
      maturity: selectedCertificate.maturity
    };
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const form_data = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      form_data.append(key, value);
    });
    if(files)
    {
      files.forEach((file, index) => {
        form_data.append(`certificate_files[]`, file);
      });
    }
    try {
      if (selectedCertificate) {
        await axios.post(`${config.apiUrl}/certificates/${selectedCertificate.id}`, form_data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        await axios.post(`${config.apiUrl}/certificates`, form_data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      setMessage('Successfully created/updated Certificate.');
      resetForm();
      onEditFinished();
      onSuccess();
    } catch (error) {
      setMessage('Could not create/update Certificate.');
    }
  };

  return (

<Card className="ccs-card my-2 p-3">
            <Card.Body>
            <h2>Issue Certificate</h2>
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3" controlId="formCCSCode">
                  <Form.Control disabled={selectedCertificate ? 'disabled' : ''} name="ccs_code" onChange={handleChange} placeholder="Certificate Owner (CCS Code)" defaultValue={formData.ccs_code} key={`ccs_code-${formResetCounter}`} />
                </Form.Group>

                <Form.Group as={Col} className="mb-3" controlId="formParentID">
                  <Form.Control disabled={selectedCertificate ? 'disabled' : ''} name="parent_id" onChange={handleChange} placeholder="Parent Certificate ID" defaultValue={formData.parent_id} key={`parent_id-${formResetCounter}`} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridPersonName">
                <Form.Control onChange={handleChange} name="person_name" placeholder="Person Name" defaultValue={formData.person_name} key={`person_name-${formResetCounter}`}
                />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridPersonCountry">
                  <CountrySelect value={formData.person_country} name={"person_country"} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridPersonRegister">
                  <Form.Control onChange={handleChange} name="person_register" placeholder="Tax/Doc. ID" defaultValue={formData.person_register} key={`person_register-${formResetCounter}`} />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                <Form.Select
                  name="lease_type"
                  value={formData.lease_type}
                  onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                >
                  <option value="">Type</option>
                  <option value="owner">Permanent</option>
                  <option value="lended">Assignment of Rights</option>
                  <option value="offer">Offer (non-binding)</option>
                </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Expiry Date:</Form.Label>
                </Form.Group>
                <Form.Group as={Col} controlId="formExpirationDate">
                  <Form.Control onChange={handleChange} name="expiration_date" type="date" value={formData.expiration_date ? formatDate(formData.expiration_date) : ''} key={`maturity-${formResetCounter}`} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridCurrency">
                <Form.Select
                  name="currency"
                  value={formData.currency}
                  key={`currency-${formResetCounter}`}
                  onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                >
                  <option value="">Currency</option>
                  <option value="USD">US Dollar</option>
                  <option value="EUR">Euro</option>
                  <option value="CHF">Swiss Franc</option>
                  <option value="BRR">Brazilian Real</option>
                </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="formFaceValue">
                  <Form.Control onChange={handleChange} type="number" name="face_value" placeholder="Monetary Value" defaultValue={formData.face_value} key={`face_value-${formResetCounter}`} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formFinancialMonetizer">
                  <Form.Control onChange={handleChange} name="financial_monetizer" placeholder="Financial Monetizer" defaultValue={formData.financial_monetizer} key={`financial_monetizer-${formResetCounter}`} />
                </Form.Group>
                <Form.Group as={Col} controlId="formFinancialApplicant">
                  <Form.Control onChange={handleChange} name="financial_applicant" placeholder="Financial Applicant" defaultValue={formData.financial_applicant} key={`financial_applicant-${formResetCounter}`} />
                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group as={Col}>
                  <Form.Label>Maturity Date:</Form.Label>
                </Form.Group>
                <Form.Group as={Col} controlId="formMaturity">
                  <Form.Control onChange={handleChange} name="maturity" type="date" value={formData.maturity ? formatDate(formData.maturity) : ''} key={`maturity-${formResetCounter}`} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formAvoidedEmissions">
                  <Form.Control onChange={handleChange} name="avoided_emissions" type="number" step="0.01" placeholder="Avoided Emissions" defaultValue={formData.avoided_emissions} key={`avoided_emissions-${formResetCounter}`} />
                </Form.Group>
                <Form.Group as={Col} controlId="formOpportunityCostPrice">
                  <Form.Control onChange={handleChange} type="number" step="0.01" name="opportunity_cost_price" placeholder="Priced Opportunity Cost" defaultValue={formData.opportunity_cost_price} key={`opportunity_cost_price-${formResetCounter}`} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formMarket">
                  <Form.Control onChange={handleChange} name="market" placeholder="Sector (Private/Public)" defaultValue={formData.market} key={`market-${formResetCounter}`} />
                </Form.Group>
                <Form.Group as={Col} controlId="formBlockchainUrl">
                  <Form.Control onChange={handleChange} name="blockchain_url" placeholder="Blockchain URL" defaultValue={formData.blockchain_url} key={`blockchain_url-${formResetCounter}`} />
                </Form.Group>
              </Row>
              <Form.Group controlId="formCertificateFiles">
              <Form.Label>Certificate Files:</Form.Label>
              <Form.Control type="file" name="certificate_files" onChange={handleFileChange} multiple />
            </Form.Group>
              {message && <p className="text-warning">{message}</p>}
              <Button size="lg" variant="outline-warning" className='my-2' type="submit">
                Submit
              </Button>
            </Form>
            </Card.Body>
          </Card>
  );
};
export default CertificateForm;
