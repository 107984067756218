import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import CountrySelect from './CountrySelect';
import axios from '../axiosConfig';
import config from '../config';

const CCSCodeForm = ({ onSuccess, selectedUser, onEditFinished }) => {
  const [message, setMessage] = useState('');
  const [formResetCounter, setFormResetCounter] = useState(0);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    email: '',
    telephone: '',
    country: '',
    public_register: '',
    address: '',
  });

  const resetForm = () => {
    setFormData({
      username: '',
      password: '',
      email: '',
      telephone: '',
      country: '',
      public_register: '',
      address: '',
    });
    setFormResetCounter((prevCounter) => prevCounter + 1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (selectedUser) {
      setFormData(selectedUser);
    } else {
      resetForm();
    }
  }, [selectedUser]);
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      if (selectedUser) {
        await axios.put(`${config.apiUrl}/user/${selectedUser.username}`, formData);
      } else {
        await axios.post(`${config.apiUrl}/users`, formData);
      }
      setMessage('Successfully created/updated CCS Code.');
      resetForm();
      onEditFinished();
      onSuccess();
    } catch (error) {
      setMessage('Could not create/update CCS Code.');
    }
  };
  return (

<Card className="ccs-card my-2 p-3">
            <Card.Body>
            <h2>Create CCS Code</h2>
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCCSCode">
                  <Form.Control onChange={handleChange} disabled={selectedUser ? 'disabled' : ''} name="username" placeholder="CCS Code (e.g: CCS1234567)" defaultValue={formData.username} key={`username-${formResetCounter}`} />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Control onChange={handleChange} name="password" type="password" placeholder="Password" />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridPasswordConfirmation">
                  <Form.Control onChange={handleChange} name="password_confirmation" type="password" placeholder="Confirm Password" />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridContact">
                  <Form.Control onChange={handleChange} name="contact" placeholder="Company Representative" defaultValue={formData.contact} key={`contact-${formResetCounter}`} />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCorporateEmail">
                  <Form.Control onChange={handleChange} name="email" type="email" placeholder="Corporate Email" defaultValue={formData.email} key={`email-${formResetCounter}`} />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridTelephone">
                  <Form.Control onChange={handleChange} name="telephone" type="phone" placeholder="Telephone" defaultValue={formData.telephone} key={`telephone-${formResetCounter}`} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Control onChange={handleChange} name="name" placeholder="Company Name" defaultValue={formData.name} key={`name-${formResetCounter}`} />
                </Form.Group>
                <Form.Group as={Col}>
                  <CountrySelect name={"country"} value={formData.country} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                </Form.Group>
                <Form.Group name="public_register" as={Col} controlId="formGridPublicRegister">
                  <Form.Control onChange={handleChange} name="public_register" placeholder="Company Tax ID/Reg." defaultValue={formData.public_register} key={`public_register-${formResetCounter}`} />
                </Form.Group>
              </Row>
              <Form.Group className="mb-3" controlId="formGridAddress">
                  <Form.Control onChange={handleChange} name="address" placeholder="Company Address"  defaultValue={formData.address} key={`address-${formResetCounter}`} />
              </Form.Group>
              {message && <p className="text-warning">{message}</p>}
              <Button size="lg" variant="outline-warning" className='my-2' type="submit">
                Submit
              </Button>
            </Form>
            </Card.Body>
          </Card>
  );
};
export default CCSCodeForm;
