import React, { useState, useEffect } from 'react';
import { Card, Table, CardGroup, Row, Button, Col } from 'react-bootstrap';
import axios from './axiosConfig';
import CertificateFiles from './components/CertificateFiles';
import config from './config';

const ClientView = ({ apiToken, onLogout }) => {
  const [endpointInfo, setEndpointInfo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const requestConfig = {
        headers: { Authorization: `Bearer ${apiToken}` },
      };

      const userResponse = await axios.get(`${config.apiUrl}/user/data`, requestConfig);
      setEndpointInfo(userResponse.data);
    };

    fetchData();
  }, [apiToken]);

  return (
    <div className='p-3'>
      {endpointInfo && (
        <div>
          <CardGroup className="card-group-container text-left d-flex align-items-center justify-content-center">
          <Row>
            <Col md={4}>
              <Card className="ccs-card p-3">
                <Card.Body>
                <h1>{endpointInfo.ccs_code}</h1>
                <h2 className="h5">Information (KYC)</h2>
                <Row>
                    {Object.entries(endpointInfo.company).map(([key, value]) => (
                      <Col key={key} sm={12}>
                        <Row>
                          <Col sm={6}>
                          <p><small>{key}</small></p>
                          </Col>
                          <Col sm={6}>
                            <p>{value}</p>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                  <Button size="lg" variant="warning"  className="w-100 my-2" onClick={onLogout}>Exit System</Button>
                </Card.Body>
              </Card>
              <Card className="ccs-card mt-3 p-3">
                <Card.Body>
                  <h2 className="h5">Support Material</h2>
                  <ul>
                    <li><small><a target="_blank" rel="noreferrer" className="support-material-link" href="https://ficoglobal.com/ifc/">Understanding the IFC Methodology</a></small></li>
                    <li><small><a target="_blank" rel="noreferrer" className="support-material-link" href="https://www.icmagroup.org/sustainable-finance/membership-governance-and-working-groups/membership/#:~:text=Banco%20FICO">Carbon Bank FICO (Brazil) - ICMA Observer Member credentials</a></small></li>
                    <li><small><a target="_blank" rel="noreferrer" className="support-material-link" href="https://wedocs.unep.org/handle/20.500.11822/9771">Inclusive Wealth Report 2014 - UN (conservation as a form of wealth)</a></small></li>
                  </ul>
                  <p><small>FICO S/A (Headquarters): Registro Publico Nº 132308 - Foreign Investment Company S/A - Republica del Panama</small><br/>
                  <small>Carbon Bank FICO (Brazilian branch): CNPJ Nº 29.289.880/0001-40 - Banco FICO de Carbono Florestal LTDA - República Federativa do Brasil</small></p>
                </Card.Body>
              </Card>
              
            </Col>
            <Col>
                <Card className="ccs-card p-3">
                    <Card.Body>
                    <h2>SDG Patrimonial Titles</h2>
                    <Row className='overflow-auto certificate-card-row'>
                        {Object.entries(endpointInfo.certificates).map(([id, certificate]) => (
                          <Col key={id} md={12}>
                          <Card className="ccs-card my-2">
                            <Card.Body>
                              <p><small>Certificate ID: {id}</small></p>
                              <Row>
                              {Object.entries(certificate).map(([key, value]) => (
                                <Col key={key} sm={6}>
                                  <Row>
                                    <Col sm={6}>
                                      <p><small className='text-left'>{key}</small></p>
                                    </Col>
                                    <Col sm={6}>
                                      {value}
                                    </Col>
                                  </Row>
                                </Col>
                              ))}
                              <CertificateFiles ccsCode={certificate.ccs_code} certificateId={id} isAdmin={false} />
                              </Row>
                            </Card.Body>
                        </Card>
                        </Col>
                        ))}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </CardGroup>
          <Col lg={12}>
                <Card className='ccs-card my-4 p-3'>
                  <Card.Body>
                    <h2>Dismemberments</h2>
                    <Row className='overflow-auto'>
                    
                        {Object.entries(endpointInfo.certificate_relationships).map(([id, certificate]) => (
                          <Table responsive key={id}>
                          <thead>
                            <tr>
                              {Object.entries(certificate).map(([key, value]) => (
                                <th key={key}>{key}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {Object.entries(certificate).map(([key, value]) => (
                                <td key={key}>{value}</td>
                              ))}
                            </tr>
                          </tbody>
                        </Table>
                          ))}
                      </Row>
                    </Card.Body>
                </Card>
              </Col>
        </div>
      )}
    </div>
  );
};

export default ClientView;
